body {
	/* border: 5px solid #5a2e9a; */
	/* Add any other desired styles for the body */
}

/* Header CSS Test */

@media (min-width: 821px) {
	.navtoggler {
		display: none;
	}
}

.headercontainer {
	border: 2px solid #8f14e6;
}

.containerrow {
	border: 3px solid #1442e6;
}

.navtoggler {
	border: 3px solid #14e6c7;
	background-color: #10beaa;
}

.navbarbranddiv {
	border: 2px solid #14e637;
	background-color: #be1027;
}

.dropdownnavdiv {
	border: 2px solid #4214e6;
	background-color: #7510be;
}

.testlogo {
	width: 100px; /* Set the desired width of the image */
	height: auto; /* Allow the height to adjust proportionally */
}

.mr-auto {
	/* border: 1px solid #e61414; */
}

/* Header CSS Test*/

/* Header CSS */

#myNav {
	border-bottom: 1px solid #ddd;
	/* Set the bottom border */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	/* Add a light shadow */
	/* border: 2px solid #d41111; */
	min-height: 70px;
	/* border: 2px solid #152ba9; */
	position: sticky;
	top: 0;
	z-index: 100;
}

.row.align-items-center {
	/* border: 1px solid #cb1e1e; */
	max-width: 1300px;
	margin: 0 auto;
}

#navcontentdiv {
	align-items: center;
	height: 20px;
	width: 30px;
	/* border: 1px solid #cb1e1e; */
}

.navbar-nav .dropdown-menu {
	height: 200px;
	/* change the value as needed */
}

#signUp {
	background-color: white;
	border-color: #9e4bfe;
	border: 2px solid;
	color: #9e4bfe;
	border-radius: 6px;
	padding-left: 8px;
	margin-left: 8px;
	margin-right: 8px;
	font-size: 12px;
	font-weight: bold;
	width: 120px;
	height: 36px;
}

#signUp:hover {
	background-color: #5a2e9a;
	border-color: #5a2e9a;
	color: white;
}

/* #signUp {
    background-color:white;
    border-color:#9e4bfe;
    border: 2px solid;
    color:#9e4bfe;
    border-radius: 6px;
    padding: 8px;
    font-size: 13px;
    font-weight: bold
}

#signUp:hover {
background-color: #5a2e9a;
border-color: #5a2e9a;
color: white;
} */

.logo {
	width: 200px; /* Set the desired width of the image */
	height: auto; /* Allow the height to adjust proportionally */
}

.navbar-toggler {
	border-color: #9e4bfe;
	border-width: 2px;
}

@media (max-width: 991px) {
	#headerlogo {
		/* border: 1px solid #0d29dc; */
		/* transform: translate(8px, 1px); */
		position: relative;
		left: 10px;
		z-index: -1;
	}
	#innerrow {
		display: none; /* Hidethe #innerrow on viewports below 1060px */
	}
	#headercolumn1 {
		display: flex;
		justify-content: center;
		align-items: center;
		/* border: 2px solid #120d94; */
		height: 35px;
	}
	#headercolumn2 {
		display: flex;
		justify-content: center;
		height: fit-content;
		/* border: 1px solid #dc0d4f; */
		margin-top: -3px;
		/* margin-bottom: 5px; */
	}
	#headercolumn3 {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

#headercolumn1 {
	/* border: 4px solid #0d7494; */
}

#headercolumn2 {
	/* border: 4px solid #940d0d; */
}

#headercolumn3 {
	/* border: 4px solid #3c940d; */
}

@media (max-width: 767px) {
	.collapse.navbar-collapse.show {
		display: inline;

		background-color: #fff;
		margin-left: 10px;
		/* border: 1px solid #e9e9e9; */
		border-radius: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
		min-height: 100px;
	}
}

#headercolumn1 {
	margin-right: 50px;
}

.btn-primary {
	background-color: #9e4bfe;
	border: 1px solid #9e4bfe;
	color: white;
	--bs-btn-active-bg: #4f2195; /* Change this value to your desired color */
	--bs-btn-active-border-color: #4f2195;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	font-size: small;
	font-weight: bold;
}

.btn-primary:hover {
	background-color: #5a2e9a;
	border-color: #5a2e9a;
	color: white;
}

#innerrow {
	border-color: #5a2e9a;
}

.dropdown {
	position: relative;
	display: inline-block;
	padding: 2px;
}

.dropdown .dropdown-menu {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	background-color: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-menu {
	display: block;
}

.signup-button-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.input-group {
	/* box-sizing: content-box; */

	display: flex;
	align-items: center;
}

.form-control {
	height: 39px;
}

#button-addon2 {
	height: 39px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.form-control::placeholder {
	font-size: 13px; /* Set the desired font size for the placeholder */
	font-weight: bold;
}

/* HomeContainerHeader */

#homecontainerheader {
	margin-top: 10px;
	/* margin-bottom: 5px; */
	display: flex;
	justify-content: center; /* Horizontally center the content */
	align-items: center; /* Vertically center the content */
	font-size: 2px; /* Adjust the font size as needed */
	height: 100px;
	/* border: 2px solid red; */
}

#homecontainerheaderfont {
	font-size: 34px; /* Adjust the font size as needed */
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	font-weight: 600;
	color: #5c5c5c;
	line-height: 1.7;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

@media (max-width: 767px) {
	#homecontainerheaderfont {
		font-size: 24px; /* Adjust the font size as needed */
	}
}

#comparisonTable {
	/* border: 1px solid purple; */
	border-radius: 6px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center; /* horizontally center the content */
	margin-bottom: 20px;
}

@media (max-width: 450px) {
	#comparisonTable {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center; /* horizontally center the content */
		margin-bottom: 5px;
	}
	.button-zoom {
		/* min-width: 110px; */
	}
	#smallbutononfilter {
		font-size: 12px;
		margin-left: 2px;
		margin-bottom: 5px;
	}
}

.btn {
	/* navbar buttons & tab buttons */
	margin-left: 2px;
}

.btn.tabs {
	/* Home Product Card & Tabs Button */
	font-size: 16px;
	margin-left: 15px;
	margin-bottom: 15px;
	border: 1px solid rgb(236, 230, 225);
	/* border: 1px solid rgb(147, 147, 147); */
	font-weight: 500;
	background-color: #ffffff;
}

.btn.tabs:hover {
	/* New border color when hovered */
	border-color: #9e4bfe; /* add a 2-pixel solid purple border */
	color: #9e4bfe;
}

.button-zoom {
	/* cursor: zoom-in; */
	transition: transform 0.5s ease;
}

.button-zoom:hover {
	transform: scale(1.02);
}

.load-more {
	margin-top: 20px;
	margin-bottom: 100px;
}

#homepagelearnmore {
	border: 1px solid rgb(143, 143, 143);
}

/* Mainly Core */

.grayfrontpage {
	background: #f5f5f5;
	/* border: 2px solid red; */
}

.grayfrontpagecardcontainer {
	/* border: 2px solid red; */
	background: #f5f5f5;
}

.ratings i {
	color: #9e82ec;
}

.install span {
	font-size: 12px;
}

.col-md-4 {
	margin-top: 27px;
}

.btn.tabs.core:hover {
	/* New border color when hovered */
	border-color: #640de6;
	color: #640de6;
}

.imageoutline {
	margin-top: 10px;
	border: 1px solid rgb(198, 198, 198);
	border-radius: 6px;
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-height: 150px; /* Adjust the max height as needed */
	min-height: 150px;
}

.image-zoom {
	/* cursor: zoom-in; */
	transition: transform 0.5s ease;
}

.image-zoom:hover {
	transform: scale(1.05);
}

.titlefont {
	font-size: large;
	font-weight: 700;
	margin-top: -10px;
}

.categoryfont {
	font-size: small;
	font-style: italic;
	color: gray;
	margin-top: 5px;
}

.greatfor {
	font-style: italic;
	color: #640de6;
	margin-top: 5px;
}

.descriptionfont {
	font-size: small;
	font-weight: 10;
	margin-top: 10px;
	min-height: 90px;
}

.card-container-layout {
	border: none;
	/* border: 5px solid; */
	min-height: 500px;
	/* flex-wrap: wrap;
align-items: center; */
}

.product-card {
	max-width: 270px;
	min-width: 270px;
	/* border: 1px solid green; */
}

/* ==========================================
    Footer CSS
  ========================================== */
a {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;
}

a:hover,
a:focus {
	text-decoration: none;
}

.form-control {
	border: 2px solid #9e4bfe;
}

.form-control:focus {
	background: #ffffff;
}

.footer {
	margin-top: 10px;
	background: #ffffff;

	/* box-shadow: inset 0 0 0 5px #ececec; */

	/* min-height: 490px; */
}

.invalid-feedback {
	/* border: 1px solid #640de6; */
}

.contentpagefooter {
	margin-top: 10px;
	background: #f2f2f2;
	/* box-shadow: inset 0 0 0 5px #ececec; */

	/* min-height: 490px; */
}

#footerrow {
	margin-top: 5px;
	margin-bottom: 20px;
}

#footercolumn {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 5px solid #640de6; */
}

#footerLogo {
	/* border: 2px solid #424c82; */
}

.footerlogosubtitle {
	/* margin-top: 15px; */
	/* margin-right: 30px; */
	/* border: solid #5691ff; */
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}

h5 {
	color: #9e4bfe; /* Set the text color */
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	font-size: 20px;
	font-weight: bold;
}

ul.list-unstyled.text-muted li a {
	color: #888888; /* Default text color */
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}

ul.list-unstyled.text-muted li a:hover {
	color: #9e4bfe;
}

ul.list-unstyled.text-muted li a:active {
	color: #5a2e9a;
}

p.small.text-muted {
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}

button.btn.tabs.button-zoom.active,
button.btn.tabs.button-zoom.active:hover {
	background-color: #9e82ec;
	color: white;
	/* border: solid white; */
}

/* Best of header content*/

.bestofminiheader {
	font-weight: bolder;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	font-size: 0.75rem;
	letter-spacing: 0.09rem;
	line-height: 1.1;
}

.bestofh1 {
	font-family: "Lato";
	font-weight: bold;
	color: #353333;
}

.bestofcontentheader {
	/* border: 1px solid #5a2e9a; */
	max-width: 1300px;
	margin-top: 5px; /* Top margin */
	margin-right: 20px;
	margin-bottom: 5px; /* Bottom margin */
	margin-left: 20px;
}

.maintextbox {
	/* border: 1px solid #cb1e1e; */
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (min-width: 768px) {
	.summary {
		margin-right: max(400px);
	}
	#footerrow {
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.bestofcontentheaderheaderbullets {
		margin-right: max(400px);
	}
}

.hyperlinkauth,
.advdis,
.comphyp {
	text-decoration: none;
	color: rgb(19, 146, 225);
}

.text-box {
	background-color: #f7f7f7;
	padding: 1px 10px 1px 10px;
	width: 100%;
	color: #85888b;
	max-width: 900px;
	max-height: auto;
	margin-bottom: 5px;
}

.graydisclaimerbox {
	font-size: 11px;
	font-weight: 500;
	padding-top: 10px;
}

p,
ul,
.hyperlinkauth {
	font-family: "Roboto";
	font-size: 16px;
	line-height: 1.5;
}

ul {
	line-height: 24px;
}

.bestofcontentheaderheaderbullets {
	margin-left: 20px;
}

.summarytableheader {
	font-size: x-large;
	font-weight: bold;
	font-family: "lato";
}

.more {
	font-size: large;
	font-weight: bold;
	font-family: Gotham, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue,
		sans-serif;
}

.moreinterlink {
	font-size: large;
	font-weight: bold;
	font-family: Gotham, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue,
		sans-serif;
	color: #9e4bfe;
}

.more a:hover {
	text-decoration: underline; /* Underline on hover */
}

#summarytablecard {
	border: solid 1px #cacaca;
	border-radius: 10px; /* Adjust the value to control the roundness of the border */
	margin-bottom: 5px;
	/* border: solid #a31e1e; */
}

#summarytablecardimage {
	/*   */
	padding: 5px;
}

.summarytablecardname {
	font-weight: bolder;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	color: #3a3a3a;
}

#summarytablecardimageoutline {
	border: solid 1px #cacaca;
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-height: 250px;
	min-height: 150px;
}

#summarytablecardexternalCTA {
	margin-bottom: 10px;
}

/* FAQ Accordian */

[id^="faqItem-"] {
	border-radius: 0%;
	/* border-left: transparent;
	border-right: transparent;
	border-bottom: transparent;
	border-top: 1px solid #c1c1c1; */
}

[id^="faqItem-"]:hover {
	border-left: 2px solid #9e4bfe;
	border-top-left-radius: 0%;
	border-right: 2px solid #9e4bfe;
	border-top-right-radius: 0%;
	border-bottom: 2px solid #9e4bfe;
	border-top: 2px solid #9e4bfe;
}

.collapsible-link {
	width: 100%;
	position: relative;
	text-align: left;
	font-weight: bold;
	text-decoration: none;
}

/* .collapsible-link:hover {
	border-color: blue; 
} */

.collapsible-link::before {
	content: "\f107";
	position: absolute;
	top: 50%;
	right: 0.8rem;
	transform: translateY(-50%);
	display: block;
	font-family: "FontAwesome";
	font-size: 1.1rem;
}

.collapsible-link[aria-expanded="true"]::before {
	content: "\f106";
}

.container {
}

/* About Author Component */

.social-icon {
	color: #9e4bfe;
	margin-top: -20px;
}

.card .body {
	font-size: 14px;
	color: #424242;
	padding: 5px;
	font-weight: 400;
}

.profile-header {
	border-top: 1px solid #c8c8c8; /* Add a top border */
	border-radius: 0%;
	max-width: 1000px;
	margin-bottom: 3px;
	border-left: none; /* Remove left border */
	border-right: none; /* Remove right border */
	border-bottom: none; /* Remove bottom border */
	border-top: 1px solid #dddddd;
}

.profile-header .profile-image img {
	margin-top: 5px;
	border-radius: 50%;
	width: 50px;
	border: 1px solid #c1c1c1;
}

.profile-header .social-icon a {
	margin: 5px;
}

#profileimagecolumnone {
	/* border: 1px solid #8a2391; */
	width: 65px;
}

#profileimagecolumntwo {
	/* border: 1px solid #8a2391; */
	width: 65px;
}

#profileimagecolumnthree {
	/* border: 1px solid #8a2391; */
	width: 65px;
}

/* Styling for the modal container */
.modal,
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999; /* Make sure the modal appears on top */
}

.modal-content {
	background-color: white;
	padding: 30px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	max-width: 80%; /* Adjust the width as needed */
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	border: none;
	font-size: larger;
	background: none;
	cursor: pointer;
}

.registration-form {
	padding: 50px 0;
	height: fit-content;
	width: fit-content;
}

.modal-content {
	/* border: #9f14b5 solid 1px; */
	height: fit-content;
	width: fit-content;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.registration-form form {
	background-color: #ffffff;
	max-width: 600px;
	padding: 20px 50px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.registration-form .form-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20%;
	width: 100px;
	height: 100px;
	margin: auto;
	margin-bottom: 50px;
	/* border: #bd1414 solid 1px; */
}

#email {
	border-radius: 20px;
}

#modalicon {
	display: block;
	margin: 0 auto;
	height: 200px;
	width: auto;
	/* border: #bd1414 solid 1px; */
}

.form-group {
	/* border: #bd1414 solid 1px; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.registration-form .item {
	border-radius: 20px;
	margin-bottom: 20px;
	padding: 10px 20px;
}

.registration-form .create-account {
	border-radius: 6px;
	width: 115px;
	height: 36px;
	font-size: 18px;
	font-weight: bold;
	background-color: #9e4bfe;
	border: none;
	color: white;
	margin-top: 10px;
}

.registration-form .social-media {
	max-width: 600px;
	background-color: #fff;
	margin: auto;
	padding: 35px 0;
	text-align: center;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	color: #9fadca;
	border-top: 1px solid #dee9ff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.registration-form .social-icons {
	margin-top: 30px;
	margin-bottom: 16px;
}

.registration-form .social-icons a {
	font-size: 23px;
	margin: 0 3px;
	color: #5691ff;
	border: 1px solid;
	border-radius: 50%;
	width: 45px;
	display: inline-block;
	height: 45px;
	text-align: center;
	background-color: #fff;
	line-height: 45px;
}

.registration-form .social-icons a:hover {
	text-decoration: none;
	opacity: 0.6;
}

@media (max-width: 576px) {
	.registration-form form {
		min-width: fit-content;
	}

	.registration-form .form-icon {
		width: 70px;
		height: 70px;
		font-size: 30px;
		line-height: 70px;
	}
}

.modalbutton {
	background-color: #9e4bfe;
	border: 1px solid #9e4bfe;
	color: white;
	--bs-btn-active-bg: #4f2195; /* Change this value to your desired color */
	--bs-btn-active-border-color: #4f2195;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	font-size: small;
	font-weight: bold;
	margin: 3px;
	min-width: 150px;
}

.modalbutton:hover {
	background-color: #5a2e9a;
	border-color: #5a2e9a;
	color: white;
}

.modal-group-button {
	display: grid;
	align-items: center;
}

/* Styling for loading skeleton */

.product-card-skeleton {
	display: flex;
	border: 1px solid #e0e0e0;
	padding: 16px;
	margin-bottom: 16px;
	background-color: #fff;
}

.product-card-skeleton-image {
	width: 100px;
	height: 100px;
	background-color: #e0e0e0;
	margin-right: 16px;
}

.product-card-skeleton-details {
	flex: 1;
}

.product-card-skeleton-title {
	height: 16px;
	width: 80%;
	margin-bottom: 8px;
	background-color: #e0e0e0;
}

.product-card-skeleton-description {
	height: 12px;
	width: 60%;
	margin-bottom: 8px;
	background-color: #e0e0e0;
}

.product-card-skeleton-price {
	height: 14px;
	width: 40%;
	background-color: #e0e0e0;
}

/* Article Styling */

.header-image-container {
	max-width: 900px;
}

.header-image {
	width: 100%; /* Make the image take up the full width of its container */
	height: auto; /* Maintain the aspect ratio */

	border-radius: 5px; /* Add rounded corners */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
